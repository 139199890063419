<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <animal-detail :show-detail="true" />
        </div>
        <div class="form-group text-center">
          <button
            class="btn btn-outline-third"
            type="submit"
          >
            Exporter
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import navbar from '../../components/navbar.vue';
import animalDetail from '../../components/identification/animal/detailInform.vue'
export default {
    components: {navbar, animalDetail},
    data(){
        return {
            pageIcon: 'la-file-export',
            navbarItems: [
                {
                    libelle: 'Sanitaire'
                },
                {
                    libelle: 'Export data',
                }
                ],
                pageTitle: 'Export data',
                pageDescription: 'Exportation des données sanitaire de moins de 6 mois',  
        }
    },
    computed: {
        ...mapGetters({
            selectedAnimal: 'identification/selectedAnimal'
        })
    }

}
</script>

<style>

</style>